/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          function showSubmenu(){

              $(".overlay").css("display", "block");

              $(".white-container").css({"visibility":"hidden", "display":"block"});

              $(".upper-blue").show('slide',{direction:'left'},200, function () {

                  $(".lower-blue").show('slide',{direction:'left'},300, function () {

                      $(".white-container").css({"visibility":"visible", "display":"hidden"}).queue(function() {

                          $(".white-container").show('slide',{direction:'down'});

                      });

                  });

              });

          }

          function hideSubmenu(){

            $(".upper-blue, .lower-blue, .white-container, .overlay").css("display", "none");

          }

          $(".trigger-nav-hover").mouseenter(showSubmenu);

          $(".white-container").mouseleave(hideSubmenu);

          $(".overlay").on("click", function(){

              hideSubmenu();

          });

          //Change Nav Colors

          var topofDiv = $(".scroll-title").offset().top; //gets offset of header

          var counter = 1;

          var navbar = $(".navbar-fixed");

          $(window).scroll(function(){

              if($(window).scrollTop() > topofDiv){

                  if(navbar.css("top") === "30px" && counter === 1){

                      counter--;

                      navbar.animate({top: "-=30",backgroundColor:"#ffffff"}, 400);

                      $(".links-container .top-level").animate({color:"#0E7390"});

                      $(".donate-anchor").animate({backgroundColor:"#8FC74A", color:"#FFFFFF"});

                      navbar.addClass("nav-shadow");

                      $(".nav-logo-white").fadeOut(100,function () {

                          $(".nav-logo-color").fadeIn(200);

                      });

                  }

              } else{

                  if(navbar.css("top") === "0px" && counter === 0){

                      counter++;
                      navbar.animate({top: "+=30",backgroundColor:"transparent"}, 400);

                      $(".links-container .top-level").animate({color:"#ffffff"});
                      $(".donate-anchor").animate({backgroundColor:"#FFFFFF", color:"#0E7390"});

                      navbar.removeClass("nav-shadow");

                      $(".nav-logo-color").fadeOut(200, function () {

                          $(".nav-logo-white").fadeIn(200);

                      });


                  }

              }
          });

          //Mobile Nav

          var topofDiv2 = $(".scroll-title").offset().top; //gets offset of header

          var counter2 = 1;

          var navbar2 = $(".mobile-logo-container");

          $(window).scroll(function(){

              if($(window).scrollTop() > topofDiv2){

                  if(navbar2.css("top") === "16px" && counter2 === 1){

                      counter2--;

                      navbar2.animate({top: "-=16",backgroundColor:"#ffffff"}, 400);

                      $(".action--open").animate({color:"#0E7390"});

                      navbar2.addClass("nav-shadow");

                      $(".mobile-logo").fadeOut(100,function () {

                          $(".mobile-logo-color").fadeIn(200);

                      });

                      $(".mobile-donate-white").fadeOut(100,function () {

                          $(".mobile-donate").fadeIn(200);

                      });

                  }

              } else{

                  if(navbar2.css("top") === "0px" && counter2 === 0){

                      counter2++;

                      navbar2.animate({top: "+=16",backgroundColor:"transparent"}, 400);

                      $(".action--open").animate({color:"#fff"});

                      navbar2.removeClass("nav-shadow");

                      $(".mobile-logo-color").fadeOut(200, function () {

                          $(".mobile-logo").fadeIn(200);

                      });

                      $(".mobile-donate").fadeOut(100,function () {

                          $(".mobile-donate-white").fadeIn(200);

                      });

                  }

              }
          });

          function openMenu() {
              classie.add(menuEl, 'menu--open');
              closeMenuCtrl.focus();
          }

          function closeMenu() {
              classie.remove(menuEl, 'menu--open');
              openMenuCtrl.focus();
          }

          var menuEl = document.getElementById('ml-menu'),
              mlmenu = new MLMenu(menuEl, {
                  // breadcrumbsCtrl : true, // show breadcrumbs
                  // initialBreadcrumb : 'all', // initial breadcrumb text
                  backCtrl : true// show back button
                  // itemsDelayInterval : 60, // delay between each menu item sliding animation
                  // onItemClick: loadDummyData  callback: item that doesn´t have a submenu gets clicked - onItemClick([event], [inner HTML of the clicked item])
              });

          var openMenuCtrl = document.querySelector('.action--open'),
              closeMenuCtrl = document.querySelector('.action--close');

          openMenuCtrl.addEventListener('click', openMenu);
          closeMenuCtrl.addEventListener('click', closeMenu);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

          function showNav(){

              $(".navbar-fixed").fadeIn();

          }

          function mainPageDisplay(){

              // setTimeout(function(){
              //
              //     $(".loading-canvas").slideUp(600);
              //     $(".wrap").slideDown(600, showNav);
              //     $("footer").css("display", "block");
              //
              // }, 400);

          }

          function effect() {

              $("#loading-logo").fadeOut(390);

              $(".colored-logo").fadeIn(410, mainPageDisplay);

          }

          $('.page-background').imagesLoaded( { background: true }, function() {

              $("#loading-logo").css("display", "block");

              new Vivus('loading-logo', {duration: 200}, effect);
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page

          if(window.location.href.indexOf("#") > -1){


              $('html, body').animate({
                  scrollTop: $("#annual").offset().top
              }, 1000);

              return false;

          }

      }
    },

    'contact':{
        init: function () {

            //Rendering Google Maps

            function initMap(lat, lng, map) {
                var myCenter = new google.maps.LatLng(lat, lng);

                var mapOptions = {
                    zoom: 16,
                    center: myCenter,
                    disableDefaultUI:true,
                    scrollwheel:false
                };

                map = new google.maps.Map(document.getElementById(map), mapOptions);

                var marker = new google.maps.Marker({

                    position:myCenter,
                    map:map

                });

                google.maps.event.addDomListener(window, "resize", function() {
                    var center = map.getCenter();
                    google.maps.event.trigger(map, "resize");
                    map.setCenter(center);
                });

            }

            $(".map").each(function(index, element){

                initMap($(this).attr("data-lat"), $(this).attr("data-lng"), $(this).attr("id"));

            });

        }

    },

    "change_lives": {

        init:function () {

            $(".dropdown-open").on("click", function(){

                var aLinkData = $(this).attr("data-open");

                $(".dropdown-container").each(function(index, element){

                   if(aLinkData === $(this).attr("data-container")){

                       if($(this).css("display") === "none"){

                           $(this).slideDown().css("display", "flex");

                       } else {

                           $(this).slideUp();

                       }

                   }

                });

            });

        }

    },

    "news_events": {

        init: function(){

            $(".dropdown-item").on("click", function(){

                var currFilter = $("#dropdownMenuLink");

                var button = $(this);

               if(button.attr("data-filter") !== currFilter.attr("data-filter")){

                   currFilter.attr("filter", button.attr("data-filter"));
                   currFilter.attr("data-filter", button.attr("data-filter"));
                   currFilter.text(button.text());

                   $(".post-container").each(function(index, element){

                       if($(this).hasClass(button.attr("data-filter")) && $(this).css("display") !== "flex" ){

                           $(this).show('slide',{direction:'down'}, 400).css("display", "flex");

                       } else if($(this).hasClass(button.attr("data-filter")) && $(this).css("display") === "flex" ) {

                           return;

                       } else {

                           $(this).hide('slide',{direction:'up'}, 400);

                       }

                   });

               }

            });

        }

    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
